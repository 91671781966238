
  import {prepareApiUrl} from '@/helper/UrlHelper';

  export default {
    props: {
      job: {
        type: Object,
        require: false,
        default: () => {},
      },
      maxWidth: {
        type: Number,
        require: false,
        default: 70,
      },
    },
    computed: {
      logoUrl() {
        return prepareApiUrl(process.env.NUXT_ENV_API_JOB_POSTING_LOGO, {
          routeParameters: {
            id: this.job.id,
          },
          getParameters: {},
        });
      },
    },
  };
